import { CkEditorContentDisplayNew } from "../../../globalComponents/CkEditorContentDisplay";
// const style = { textAlign: "justify" };

const Overview = ({ data }) => {
  const Text = data?.Description || "";
  return (
    <>
      <CkEditorContentDisplayNew text={Text} />

      {/* Overview
      <ReadMore
        text={Text}
        fontSize={"1rem"}
        color="#777"
        lineHeight="20px"
        showLine="5"
        style={style}
      /> */}
    </>
  );
};

export default Overview;
