import axios from "axios";
import { IoIosTimer } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineArrowOutward } from "react-icons/md";
import { useEffect, useState } from "react";

import TextBg from "../../../globalComponents/molecules/TextBg";
import CareerFormPopUp from "./CareerFormPopUp";

// import { SORTED_BY_CAREER } from "../../../../assets/constants/filters";
// import { CAREER_POSITIONS } from "./../../../../assets/constants/career";
import { getFullUrl } from "../../../../assets/constants/apiUrls";
import "./position.css";

const Position = () => {
  const [filter, setFilter] = useState("All");
  const [expanded, setExpanded] = useState({});
  const [positions, setPositions] = useState([]);
  const [isCareerFormOpen, setIsCareerFormOpen] = useState(false);

  const handleJobForm = () => setIsCareerFormOpen(!isCareerFormOpen);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get(getFullUrl("/api/career-positions"));
        setPositions(response.data.data);
      } catch (error) {
        console.error("Error fetching positions: ", error);
      }
    };

    fetchPositions();
  }, []);

  const filteredPositions =
    filter === "All"
      ? positions
      : positions.filter((position) =>
          position.attributes.Position_Name.includes(filter)
        );

  const toggleExpand = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const PositionButton = [
    "All",
    ...new Set(positions.map((position) => position.attributes.Position_Name)),
  ];
  //trim ke liye
  const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <div>
      <TextBg text={"JOIN US"} fontWeight={900} fontSize="4rem" />
      <div className="container">
        <div className="row">
          <div className="filterTabs">
            {PositionButton.map((filterOption, idx) => (
              <button
                key={idx}
                onClick={() => setFilter(filterOption)}
                className="job-tags"
                style={{
                  background:
                    filter === filterOption ? "black" : "rgb(243 117 53)",
                  borderRadius: ".25rem",
                  padding: ".75rem 1.25rem",
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: 12,
                  cursor: "pointer",
                  margin: ".5rem",
                  textWrap: "nowrap",
                }}
              >
                {filterOption}
              </button>
            ))}
          </div>
          <div className="careerFormContainer">
            {filteredPositions.map((position, index) => (
              <div className="col-md-4 misnoryGrid" key={index}>
                <div className="joinUsCard">
                  <p className="positionTitle">
                    {position.attributes.Position_Name}
                  </p>
                  <p className="positionLocation">
                    <span>
                      <CiLocationOn className="locationIcons" />
                    </span>{" "}
                    {position.attributes.Position_Location}
                  </p>
                  <div className="fullPositionDesc">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: expanded[index]
                          ? position.attributes.Position_Description
                          : `${stripHtml(
                              position.attributes.Position_Description
                            ).substring(0, 70)}...`,
                      }}
                      className={`positionDescription ${
                        expanded[index] ? "expanded" : ""
                      }`}
                    />
                  </div>
                  {stripHtml(position.attributes.Position_Description).length >
                    70 && (
                    <button
                      className="readMoreButton"
                      onClick={() => toggleExpand(index)}
                    >
                      {expanded[index] ? "Read Less" : "Read More"}
                    </button>
                  )}
                  <div className="setAllButton">
                    <div className="FulltimeStyle">
                      <IoIosTimer /> Full Time
                    </div>

                    <div className="applyButton" onClick={handleJobForm}>
                      Apply <MdOutlineArrowOutward className="ArrowButton" />
                    </div>

                    {isCareerFormOpen && (
                      <CareerFormPopUp
                        isOpen={isCareerFormOpen}
                        onClose={handleJobForm}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Position;
