import { FcGallery } from "react-icons/fc";
import InfiniteSlides from "../../../globalComponents/slickCrousel/InfiniteSlides";
import PropertyTextContent from "./PropertyTextContent";
import BreadCrumb from "../../../globalComponents/BreadCrumb/BreadCrumb";

import useDeviceSize from "../../../../hooks/useDeviceSize";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../assets/constants";
import Heading from "../../../globalComponents/molecules/Heading";
import "./propertyDetailsCard.css";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1, // Number of slides to scroll per transition
  autoplay: true, // Set to true for automatic slide change
  autoplaySpeed: 1500,
  speed: 1000,
  lazyLoad: true,
  initialSlide: 1,
  arrows: true,
};

// const borderStyle = (color) => ({
//   border: `2px solid ${color}`,
// });

const PropertyDetailsCard = ({ propertyDetail }) => {
  const isMobile = useDeviceSize();

  const { Project_Name = "", Image = EMPTY_ARRAY } =
    propertyDetail || EMPTY_OBJECT;
  const SLIDES =
    Image?.data?.map((item) => ({ img: item?.attributes?.url })) || EMPTY_ARRAY;

  return (
    <div className="container-fluid px-lg-4">
      <div className="d-flex justify-content-center">
        <Heading
          tag="h1"
          text={Project_Name}
          fontSize="2.5rem"
          className="propertyName heading"
          fontWeight={700}
          color="#EF750f"
          // lineHeight="4rem"
        />
        {/* <TextBg /> */}
        {/* <Heading text={Project_Name} className="pl-5" fontSize="2rem" /> */}
      </div>

      <div className="">
        {isMobile ? null : <BreadCrumb />}
        <div
          className={`row px-lg-3 center-item ${
            isMobile ? "flex-column-reverse" : ""
          }`}
        >
          <div className="col-md-4 col-xsm-12 col-sm-12  propertyTextBox center-item h-100">
            <PropertyTextContent propertyDetail={propertyDetail} />
          </div>

          <div className="col-md-8 propertyDetailsCard-container h-100 ">
            <InfiniteSlides
              settings={settings}
              data={SLIDES}
              className="propertyGallerySlider border-2"
            />
            {/* <PropertyGalleryCard img={img} /> */}
            <div className="imgCountBtn">
              <FcGallery fontSize={"1.5rem"} /> {SLIDES.length} Photos
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsCard;

// export function PropertyGalleryCard({ img }) {
//   return (
//     <>
//       <div className="d-flex h-100">
//         <div className="col-md-6 col-sm-12  propertyDetailsCard-main-img">
//           <img alt="" src={img} className="col-md-12" />
//         </div>
//         <div className="col-md-6 col-sm-12 propertyDetailsCard-side-img">
//           <div>
//             <img
//               alt=""
//               src="https://www.wealth-clinic.com/property_media/56511705386406.jpg"
//               className="col-md-6"
//             />
//             <img
//               alt=""
//               src="https://www.wealth-clinic.com/property_media/56511705386406.jpg"
//               className="col-md-6 sideImg2"
//             />
//           </div>

//           <div>
//             <img
//               alt=""
//               src="https://www.wealth-clinic.com/property_media/56511705386406.jpg"
//               className="col-md-6 "
//             />
//             <img
//               alt=""
//               src="https://www.wealth-clinic.com/property_media/56511705386406.jpg"
//               className="col-md-6 sideImg4"
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
