import { useState, useEffect, useRef } from "react";

import SectionWrapper from "../../../globalComponents/SectionWrapper";
import Amenities from "../propertyDetailsComponent/Amenities";
import Brochure from "../propertyDetailsComponent/Brochure";
import FloorPlans from "../propertyDetailsComponent/FloorPlans";
import ContactExpertForm from "../../../contactExpertForm/ContactExpertForm";
import Overview from "../propertyDetailsComponent/Overview";
import PrizeList from "../propertyDetailsComponent/PrizeList";
import AboutBuilder from "../propertyDetailsComponent/AboutBuilder";
import Specifications from "../propertyDetailsComponent/Specifications";
import Faqs from "../propertyDetailsComponent/Faqs";
import LocationMap from "../propertyDetailsComponent/LocationMap";

import useApiFetcher from "../../../../hooks/useApiFetcher";
import { getPropertyPopulatedFieldsUrlById } from "../../../../assets/constants/apiUrls";
import "./propertyMenuDetails.css";

// const PropertyDetailsButtons = [
//   "Overview",
//   "Prize List",
//   "Floor Plans",
//   "Brochure",
//   "Amenities",
//   "Locations & Landmarks",
//   "About Builder",
//   "Faq's",
// ];

const componentMap = {
  Overview: Overview,
  Amenities: Amenities,
  "Locations & Landmarks": LocationMap,
  // connectivity,
  Brochure: Brochure,
  "Price List": PrizeList,
  "Floor Plans": FloorPlans,
  Specifications: Specifications,
  "About Builder": AboutBuilder,
  "FAQ'S": Faqs,
  // Disclaimer
};

const PropertyDetailsButtons = Object.keys(componentMap);

const PropertyMenuDetails = ({ projectId }) => {
  const url =
    getPropertyPopulatedFieldsUrlById(
      [
        "builder",
        "Floor_Plan",
        "Add_Price_List",
        "Specifications",
        "Location_Map",
        "Brochures",
        "Faqs",
        "amenities][populate][amenity_category",
        "amenities",
      ],
      projectId
    ) + "populate[amenities][populate][Icon]=*";

  const [activeComponent, setActiveComponent] = useState("Overview");

  // const GetComponent = componentMap[activeComponent];
  const [propertyDetails, error, isLoading] = useApiFetcher(url);
  const sectionRef = useRef({});

  const handleScrollToSection = (sectionName) => {
    const element = sectionRef.current[sectionName];
    if (element) {
      // Scroll element into view
      element.scrollIntoView({ behavior: "smooth", block: "start" });

      // setTimeout(() => window.scrollBy(0, -164), 300);
    }
  };

  const setSectionRef = (sectionName) => (ele) => {
    if (ele) sectionRef.current[sectionName] = ele;
  };

  useEffect(() => {
    setActiveComponent("Overview");
  }, []);

  const handleEvent = (componentName) => {
    setActiveComponent(componentName);
  };

  if (error) return <div>{error?.message}</div>;
  if (isLoading) return <div>Loading...</div>;
  if (!propertyDetails) return <div>No Data</div>;

  // TODO need to added this logic
  // const checkMappedData = {
  //   Overview: propertyDetails?.attributes?.Description,
  //   Amenities: propertyDetails?.attributes?.amenities,
  //   "Locations & Landmarks": propertyDetails?.attributes?.Location_Map,
  //   // Brochure: propertyDetails?.attributes?.Brochure.length > 0,
  //   "Price List": propertyDetails?.attributes?.Add_Price_List,
  //   "Floor Plans": propertyDetails?.attributes?.Floor_Plan,
  //   Specifications: propertyDetails?.attributes?.Specifications,
  //   "About Builder": propertyDetails?.attributes?.builder,
  //   "FAQ'S": propertyDetails?.attributes?.Faqs.length > 0,
  // };

  // const data = Object.keys(checkMappedData).reduce((acc, nextItem) => {
  //   console.log(nextItem, "===", checkMappedData[nextItem]);
  //   if (checkMappedData[nextItem]) {
  //     console.log(nextItem);
  //   }

  //   return acc;
  // }, {});
  // console.log(data);

  const description = propertyDetails?.attributes;

  return (
    <div className="property-menu-container">
      {/* <div>
        Hello
        {checkMappedData["Overview"] &&
          createElement(checkMappedData["Overview"], {
            data: description,
            projectId: projectId,
          })}
      </div> */}
      <div className="propertyMenuDetails">
        {PropertyDetailsButtons.map((buttonName, idx) => (
          <div
            key={idx}
            onClick={() => {
              handleScrollToSection(buttonName);
              handleEvent("Overview");
            }}
            className={`menu-button ${
              activeComponent === buttonName ? "active" : ""
            }`}
          >
            {buttonName}
          </div>
        ))}
      </div>

      <div className="d-flex content-container">
        {/* <div className=" propertyMenuDetails-content py-4 px-5 newBoxShadow">
          {GetComponent && (
            <GetComponent data={description} projectId={propertyId} />
          )}
        </div> */}

        <div className="row">
          <div className="col-12 col-md-8 propertyMenuDetails-content py-4 px-5 newBoxShadow">
            {activeComponent === "Overview"
              ? PropertyDetailsButtons.map((buttonName, idx) => {
                  const GetComponent = componentMap[buttonName];
                  return (
                    <div key={idx} ref={setSectionRef(buttonName)}>
                      <SectionWrapper title={buttonName} isVisible={true}>
                        <GetComponent
                          data={description}
                          projectId={projectId}
                        />
                      </SectionWrapper>
                    </div>
                  );
                })
              : {
                  /* <SectionWrapper title={activeComponent} isVisible={true}>
                {createElement(componentMap[activeComponent], {
                  data: description,
                  projectId: projectId,
                })}
              </SectionWrapper> */
                }}
          </div>

          <div className="col-12 col-lg-4">
            <div className="propertyDetailsPageFormContainer ">
              <ContactExpertForm className="contactExpertFormWidth p-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyMenuDetails;

// {/* <div className=" propertyMenuDetails-content py-4 px-5 newBoxShadow">
//   {GetComponent && (
//     <GetComponent data={description} projectId={projectId} />
//   )}
// </div> */}
