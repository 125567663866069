import axios from "axios";
import { useEffect, useState } from "react";
import { IoBedOutline } from "react-icons/io5";
// import { FaRupeeSign } from "react-icons/fa";

import Heading from "../../../globalComponents/molecules/Heading";
import CallBackPopUpForm from "../../../globalComponents/CallBackPopUpForm";
import RatingStar from "../../../globalComponents/RatingStar/RatingStar";
import ConvertStringToHtml from "../../../globalComponents/ConvertStringToHtml";
// import { RatingDisplay } from "../../../globalComponents/RatingDisplay/RatingDisplay";

import { formatIndianCurrency } from "../../../../helpers/helper";
import { getFullUrl } from "../../../../assets/constants/apiUrls";
import { EMPTY_OBJECT } from "../../../../assets/constants/index";
import "./propertyTextContent.css";

const PropertyTextContent = ({ propertyDetail }) => {
  const {
    propertyId,
    Project_Name,
    Min_Price,
    Max_Price,
    // Project_Disclaimer,
    description,
    Address,
    Project_Configuration = "",
    RegNo,
  } = propertyDetail || EMPTY_OBJECT;

  const [isOpen, setIsOpen] = useState();
  const [viewCount, setViewCount] = useState(1);

  const handleCallBackPopUpBtn = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchViewCount = async () => {
      try {
        const fullUrl = getFullUrl(
          `/api/project-views?filters[projectId][$eq]=${propertyId}`
        );

        const viewCountResponse = await axios.get(fullUrl);
        const view = viewCountResponse.data?.data[0]?.attributes.viewCount;

        setViewCount(view);
      } catch (error) {
        console.error("Failed to fetch view count:", error);
      }
    };
    fetchViewCount();
  }, [propertyId]);
  const maxPrice = formatIndianCurrency(Max_Price);
  const minPrice = formatIndianCurrency(Min_Price);

  return (
    <div className="col-lg-12 col-md-12  col-sm-12 propertyTextContent-container">
      <div className="card-main-head">{Project_Name}</div>

      <p className="card-city-sec-1">
        <IoBedOutline /> {Address}
      </p>

      <div className="row configuration-container">
        <div className="col-md-2 p-0 configureImg">
          <img
            src="https://www.wealth-clinic.com/frontend/images/property/Icon%203.png"
            alt=""
          />
        </div>
        <div className="col-md-10 p-0 configureTexts">
          <Heading
            text="Configurations"
            color="#888"
            fontSize="14px"
            fontWeight="600"
            className="overflow-hidden"
          />

          <Heading
            text={Project_Configuration}
            fontSize="0.75rem"
            className="py-1"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between py-1">
        <div className="card-city-price-1">
          {/* <FaRupeeSign />  */}₹ {minPrice} - ₹ {maxPrice}
        </div>
        <div className="propertyDetailsRegNo">RegNo : {RegNo} </div>
      </div>

      <p className="city-details-desc">
        <Heading
          text={<ConvertStringToHtml htmlData={description} />}
          color={"444"}
          fontSize=".75rem"
          lineHeight="1.2rem"
          showLine="3"
          className="overflow-hidden"
        />
      </p>

      <div className="row  properties-card-btn-container">
        <button className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5 whatsapp-btn ">
          {/* <span className="mx-2 text-white-8">172 Views</span> */}
          <span className="mx-2 text-white-8">{viewCount} Views</span>

          <i className="bi bi-eye"></i>
        </button>

        <button className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5 phone-btn ">
          <span className="mx-2 text-white-8">0 Enquiries</span>
          <i className="bi bi-headphones"></i>
        </button>

        <button className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 phone-btn 2 my-3">
          <span className="mx-2 text-white-8" onClick={handleCallBackPopUpBtn}>
            Get a call back
          </span>
          <i className="fa-solid fa-phone-flip"></i>
        </button>
        <div className="d-flex justify-content-center">
          <RatingStar projectId={propertyId} />
          {/* <RatingDisplay projectId={propertyId} /> */}
        </div>
      </div>
      {isOpen && (
        <CallBackPopUpForm
          isOpen={isOpen}
          onClose={handleCallBackPopUpBtn}
          formName={`${Project_Name} propertyDetails page GetCallBack form`}
        />
      )}
    </div>
  );
};

export default PropertyTextContent;
