import { useState } from "react";

import InputCustom from "../molecules/InputCustom";
import ButtonDarkBlue from "../molecules/ButtonDarkBlue";
import PopUpCard from "../PopUpCard/PopUpCard";

// import { submitForm } from "./form.services";
import { submissionOnZoho } from "../../../assets/constants/zoho";
import { COMPANY_SECONDARY_LOGO } from "../../../assets/constants/companyLogos";
import { submitForm } from "./form.services";

const initialFormFields = {
  name: "",
  phone: "",
  email: "",
};

// const isFieldEmpty = (newObj) => {
//   return newObj.email.trim() && newObj.phone.trim() && newObj.name.trim();
// };

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const popPupStyle = {
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
};

const callBackFormContainer = {
  ...centerStyle,
  flexDirection: "column",
  background: "#fff",
  width: "300px",
  height: "350px",
  borderRadius: ".25rem",
};

const CallBackPopUpForm = ({ isOpen, onClose, formName = "" }) => {
  return (
    <PopUpCard
      isOpen={isOpen}
      onClose={onClose}
      style={popPupStyle}
      removeBtn={false}
    >
      <CallBackForm onClose={onClose} formName={formName} />
    </PopUpCard>
  );
};

export default CallBackPopUpForm;

export function CompanyLogoSection({ onClose, hideCloseBtn }) {
  return (
    <div className="d-flex pb-3 justify-content-between w-75">
      <div className="logoOnLoginForm">
        <img
          alt="company-logo"
          src={COMPANY_SECONDARY_LOGO}
          className="w-100"
        />
      </div>
      {hideCloseBtn && (
        <button onClick={onClose} className="closeBtn">
          X
        </button>
      )}
    </div>
  );
}

export function CallBackForm({
  onClose,
  hideCloseBtn = true,
  styleForm,
  formBtnText,
  formName = "",
  ...props
}) {
  const [formValues, setFormValues] = useState(initialFormFields);
  const { name, phone, email } = formValues;

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const formValidationErrors = validateForm(formValues);
    // if (!isFieldEmpty(formValues)) {
    //   alert("Something is missing");
    //   return;
    // }
    if (Object.keys(formValidationErrors).length === 0) {
      await submissionOnZoho(formValues);
      await submitForm(formValues);
      // sendWhatsApp(formField);
      setFormValues(initialFormFields);
    }

    // if (window.Email) {
    //   sendEmail(formField);
    // } else {
    //   console.error("window.Email is not defined");
    // }
  };

  const handleInputChange = (event) => {
    setFormValues({
      formName,
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <div className="center-item h-100" {...props}>
      <div style={callBackFormContainer} className={`${styleForm} center-item`}>
        <CompanyLogoSection onClose={onClose} hideCloseBtn={hideCloseBtn} />
        <form onSubmit={handleSubmitForm}>
          <InputCustom
            placeholder="Enter Name"
            className="border px-3 rounded-2 w-100"
            value={name}
            name="name"
            onChange={handleInputChange}
          />
          <InputCustom
            placeholder="Enter Phone"
            className="border px-3 rounded-2"
            value={phone}
            name="phone"
            onChange={handleInputChange}
            required
          />
          <InputCustom
            placeholder="Enter Email"
            className="border px-3 rounded-2 w-100"
            value={email}
            name="email"
            onChange={handleInputChange}
            required
          />
          <ButtonDarkBlue
            name={formBtnText || "Get Call Back"}
            className="rounded-2"
          />
        </form>
      </div>
    </div>
  );
}

const validateForm = (formValues) => {
  const errors = {};
  if (!formValues.name.trim()) {
    errors.name = "Name is required";
  }

  if (!formValues.email.trim()) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
    errors.email = "Email is invalid";
  }

  if (!formValues.phone.trim()) {
    errors.phone = "Phone Number is required";
  } else if (!/^\d{10}$/.test(formValues.phone)) {
    errors.phone = "Phone Number must be 10 digits";
  }

  return errors;
};
