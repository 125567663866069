import { Rating } from "@mui/material";
import { useEffect, useState } from "react";
import { getMethod, postMethod } from "../../../services";
import { EMPTY_ARRAY } from "../../../assets/constants";


const postRatingParams = (rating, ip, projectId) => ({
  data: {
    ratingStar: rating,
    ipAddress: ip,
    projectId: `${projectId}`,
  },
});

// const projectId = 214;

const RatingStar = ({ projectId }) => {
  const Provider = "https://bold-approval-c005df0fb8.strapiapp.com";
  // "http://localhost:1337";
  // "proxy": "http://localhost:1337",
  // "proxy": "https://www.wealth-clinic.com",
  const [initialRating, setInitialRating] = useState(0);
  const [ipAddress, setIpAddress] = useState(null);
  const [isRatingExist, setIsRatingExist] = useState(EMPTY_ARRAY);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const fetchIpAddress = async () => {
    const ipData = await getMethod("https://api.ipify.org/?format=json");
    // const url = `${Provider}/api/project-ratings/${projectId}`;
    // const data = await getMethod(url);
    // console.log(data);
    setIpAddress(ipData.ip);
    return ipData?.ip;
  };

  useEffect(() => {
    fetchIpAddress();
  }, [triggerFetch]);

  useEffect(() => {
    const getUserRating = async () => {
      const getUserRatingUrl = (ip, projectId) =>
        `${Provider}/api/project-ratings?filters[ipAddress][$eqi]=${ip}&filters[$and][1][projectId][$eq]=${projectId}`;
      const url = getUserRatingUrl(ipAddress, projectId);
      const userRating = await getMethod(url);
      setIsRatingExist(userRating?.data || EMPTY_ARRAY);
    };

    fetchIpAddress().then((ipData) => getUserRating(ipData));
  }, [ipAddress, triggerFetch, projectId]);

  const handleRating = (event, newValue) => setInitialRating(newValue);

  const handleSubmitRatingBtn = async () => {
    try {
      const rating = await fetchIpAddress().then((ipData) =>
        postRatingParams(initialRating, ipData, projectId)
      );

      await postMethod(
        `${Provider}/api/project-ratings`,
        rating,
        "rating added"
      );
      setTriggerFetch(!triggerFetch);
    } catch (err) {
      console.error("Error while  submitting ratings: ", err);
    }
  };

  return (
    <>
      {isRatingExist.length > 0 ? (
        <Rating value={isRatingExist[0]?.attributes?.ratingStar} disabled />
      ) : (
        <>
          <Rating value={initialRating} onChange={handleRating} />
          {initialRating > 1 && (
            <div
              className="ratingBtn border p-1 mx-1"
              style={{ fontSize: ".75rem", cursor: "pointer" }}
              onClick={handleSubmitRatingBtn}
            >
              Post Rating
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RatingStar;

