import { useRef, useState } from "react";

const PdfViewer = ({ pdfUrl, w, h }) => {
  const urlRef = useRef();
  const [initialPdfUrl, setInitialPdfUrl] = useState("");

  const downLoadPdf = (pdfUrl) => {
    setInitialPdfUrl(pdfUrl);
  };

  return (
    <object data={pdfUrl} type="application/pdf" width={w} height={h}>
      <p>
        Your browser does not support PDFs.{" "}
        <a
          href={initialPdfUrl}
          ref={urlRef}
          onClick={() => downLoadPdf(pdfUrl)}
        >
          Download the PDF
        </a>
        .
      </p>
    </object>
  );
};

export default PdfViewer;
