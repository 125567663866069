import { useState } from "react";

import PdfViewer from "./../../../globalComponents/PdfViewer/PdfViewer";
import { BrochureDownloadForm } from "./BrochureDownloadForm";

import { EMPTY_ARRAY } from "../../../../assets/constants";

const divStyle = {
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url('https://old.wealth-clinic.com/property_media/86841705386391.jpg')`,
  backgroundSize: "cover", // or 'contain' depending on the desired effect
  backgroundPosition: "center",
  width: "100%",
  padding: "3rem 0",
};

const Brochure = ({ data, className }) => {
  const Brochures = data?.Brochures || EMPTY_ARRAY;
  const [viewPdf, setViewPdf] = useState(null);

  let pdfUrl = Brochures[0]?.Brochure?.data?.attributes?.url;

  return (
    <div className={` col-md-12  col-sm-12 col-xs-12`}>
      {viewPdf ? (
        <PdfViewer w="100%" h="500px" pdfUrl={pdfUrl} />
      ) : (
        <div className="" style={divStyle}>
          <div className="center-item mb-2 text-white">
            Fill The Form To Download Brochure
          </div>
          <BrochureDownloadForm
            hideCloseBtn={false}
            styleForm="border"
            formBtnText="Request Download"
            formName="Brochure form from propertyDetails Page"
            pdfUrl={pdfUrl}
            setViewPdf={setViewPdf}
          />
        </div>
      )}
    </div>
  );
};

export default Brochure;

// export function BrochureListForm() {
//   return (
//     <div className={` col-md-12  col-sm-12 col-xs-12`}>
//       <div className="d-flex my-2 ">
//         <Heading
//           text={"Please Enter Your Details"}
//           fontSize={"1rem"}
//           color="111"
//           fontWeight="600"
//           style={{ lineHeight: "1.5rem" }}
//         />
//       </div>
//       <form
//         onClick={(e) => e.preventDefault()}
//         className={`col-md-5 border rounded-3 p-3`}
//       >
//         <InputCustom placeholder="Enter Name" className="px-3 rounded-2" />
//         <InputCustom placeholder="Enter Email" className="px-3 rounded-2" />

//         <ButtonDarkBlue name="Download Brochure" className={"px-3 rounded-2"} />
//       </form>
//     </div>
//   );
// }
