import { useState } from "react";
import { submissionOnZoho } from "../../../../assets/constants/zoho";
import { submitForm } from "../../../globalComponents/CallBackPopUpForm/form.services";
import { CompanyLogoSection } from "../../../globalComponents/CallBackPopUpForm/CallBackPopUpForm";
import InputCustom from "../../../globalComponents/molecules/InputCustom";
import ButtonDarkBlue from "../../../globalComponents/molecules/ButtonDarkBlue";

const initialFormFields = {
  name: "",
  phone: "",
  email: "",
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const callBackFormContainer = {
  ...centerStyle,
  flexDirection: "column",
  background: "#fff",
  width: "300px",
  height: "350px",
  borderRadius: ".25rem",
};

export function BrochureDownloadForm({
  onClose,
  hideCloseBtn = true,
  styleForm,
  formBtnText,
  formName = "",
  setViewPdf,
  pdfUrl,
  ...props
}) {
  const [formValues, setFormValues] = useState(initialFormFields);
  const { name, phone, email } = formValues;

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const formValidationErrors = validateForm(formValues);

    if (Object.keys(formValidationErrors).length === 0) {
      submissionOnZoho(formValues).then(() => setViewPdf(pdfUrl));
      // setViewPdf(pdfUrl);
      await submitForm(formValues);
      // sendWhatsApp(formField);
      setFormValues(initialFormFields);
    }

    // if (window.Email) {
    //   sendEmail(formField);
    // } else {
    //   console.error("window.Email is not defined");
    // }
  };

  const handleInputChange = (event) => {
    setFormValues({
      formName,
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <div className="center-item h-100" {...props}>
      <div style={callBackFormContainer} className={`${styleForm} center-item`}>
        <CompanyLogoSection onClose={onClose} hideCloseBtn={hideCloseBtn} />
        <form onSubmit={handleSubmitForm}>
          <InputCustom
            placeholder="Enter Name"
            className="border px-3 rounded-2 w-100"
            value={name}
            name="name"
            onChange={handleInputChange}
          />
          <InputCustom
            placeholder="Enter Phone"
            className="border px-3 rounded-2"
            value={phone}
            name="phone"
            onChange={handleInputChange}
            required
          />
          <InputCustom
            placeholder="Enter Email"
            className="border px-3 rounded-2 w-100"
            value={email}
            name="email"
            onChange={handleInputChange}
            required
          />
          <ButtonDarkBlue
            name={formBtnText || "Get Call Back"}
            className="rounded-2"
          />
        </form>
      </div>
    </div>
  );
}

const validateForm = (formValues) => {
  const errors = {};
  if (!formValues.name.trim()) {
    errors.name = "Name is required";
  }

  if (!formValues.email.trim()) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
    errors.email = "Email is invalid";
  }

  if (!formValues.phone.trim()) {
    errors.phone = "Phone Number is required";
  } else if (!/^\d{10}$/.test(formValues.phone)) {
    errors.phone = "Phone Number must be 10 digits";
  }

  return errors;
};
