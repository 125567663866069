import Heading from "../../globalComponents/molecules/Heading";
import QueryForm2 from "../../globalComponents/queryForm2/QueryForm2";

import "./QuerySectionTemplate.css";

const QuerySectionTemplate = () => {
  return (
    <section className="m-0 my-5 d-flex flex-raw QuerySectionTemplate">
      <div className="container">
        <div className="enquiryFormTitle">
          <Heading
            text={"ANY ENQUIRIES"}
            fontWeight={"700"}
            fontSize={"2.4rem"}
            color={"var(--global-heading-color)"}
            className="callback"
          />
          <h4 className="text-white text-center">Fill This Query Form</h4>
        </div>
        <div className="row">
          <div className="col-md-5">
            <QueryForm2 />
          </div>
          <div className="col-md-7 px-0 px-sm-0  vertical-center center-item">
            <GoogleReviewsFrame />
          </div>
        </div>
      </div>

      {/* <EarthSection /> */}
      {/* <EarthWithStar /> */}
    </section>
  );
};

export default QuerySectionTemplate;

// export function GoogleReviews() {
// have to add script inside public html file
//   return (
//     <div className="fixedHeight border border-danger">
//       <script
//         src="https://static.elfsight.com/platform/platform.js"
//         data-use-service-core
//         defer
//       ></script>

//       <div
//         className="elfsight-app-f38e51cd-1bae-4861-9b25-fd50399899d2"
//         data-elfsight-app-lazy
//       ></div>
//     </div>
//   );
// }

export function GoogleReviewsFrame() {
  return (
    <div className="googleReviewsFrame">
      <iframe
        loading="lazy"
        src="https://f38e51cd1bae48619b25fd50399899d2.elf.site"
        width="100%"
        height="600"
        title="Embedded Content"
      />
    </div>
  );
}
