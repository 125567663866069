import QuestionAnswer from "../../../globalComponents/molecules/QuestionAnswer";
import { EMPTY_ARRAY } from "../../../../assets/constants";

const Faqs = ({ data }) => {
  const FAQS = data?.Faqs || EMPTY_ARRAY;
  if (FAQS.length === 0) return null;
  return (
    <div>
      {FAQS?.map((faq) => (
        <QuestionAnswer
          key={faq?.id}
          question={faq?.Question}
          answer={faq?.Answer}
        />
      ))}
    </div>
  );
};

export default Faqs;
