import CustomLink from "../../CustomLink/CustomLink";
import { ButtonDarkBlue } from "./../../globalComponents/molecules/ButtonDarkBlue";

import URL, { URL_PARAMS } from "../../../assets/constants/url";
import "./finalexplore.css";

const cardData = [
  {
    title: "Luxury",
    imageUrl: "https://www.wealth-clinic.com/property_media/43821691649499.jpg",
  },
  {
    title: "MID-RANGE",
    imageUrl: "https://www.wealth-clinic.com/property_media/67581703666273.jpg",
  },
  {
    title: "AFFORDABLE",
    imageUrl: "https://www.wealth-clinic.com/property_media/23321696312251.jpg",
  },
  {
    title: "READY TO MOVE IN",
    imageUrl:
      "https://www.wealth-clinic.com/project_category_media/20921695721064.webp",
  },
];

const FinalExplore = () => {
  // const isMobile = useDeviceSize();
  return (
    <div className="container bgFinalExplore">
      <div className="row center-item">
        {cardData.map((card, idx) => (
          <div
            className="col-6 col-lg-3 my-md-3 col-md-6 center-item col-sm-6 col-xs-6"
            key={idx}
            style={
              {
                // width: isMobile ? "50%" : "auto",
                // margin: isMobile ? "0" : ".5rem",
              }
            }
          >
            <ExploreCard data={card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinalExplore;

function ExploreCard({ data, className }) {
  const { title, imageUrl } = data;
  return (
    <div
      className={`card ${className}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <p className="headingExplore">{title}</p>
      <CustomLink to={`${URL.SEARCH}?${URL_PARAMS.CATEGORY}=${title}`}>
        <ButtonDarkBlue
          className="exploreViewsDetailsBtn"
          name="View Details"
        />
      </CustomLink>
    </div>
  );
}
