import { Link, useLocation } from "react-router-dom";
import "./breadCrumb.css";

const BreadCrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((eachPath) => eachPath);

  return (
    <span className="breadcrumb flat">
      <Link to="/">Home</Link>
      {pathnames.map((name, idx) => {
        const routeTo = `/${pathnames.slice(0, idx + 1).join("/")}`;

        const isLast = idx === pathnames.length - 1;

        return (
          <div key={name} className="breadCrumb-item">
            {!isLast && <Link to={routeTo}>{name}</Link>}
            {isLast && <span className="currentRoute">{name}</span>}
          </div>
        );
      })}
    </span>
  );
};

export default BreadCrumb;
