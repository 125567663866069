import { useState } from "react";

import { Checkbox } from "@mui/material";

import { EMPTY_FUNCTION } from "../../assets/constants";
import "./multiSelectDropdown.css";

const MultiSelectDropdown = ({ name, data, cb = EMPTY_FUNCTION, ...props }) => {
  const checkedStatus = data?.reduce((acc, nextElement) => {
    if (!acc[nextElement]) {
      acc[nextElement] = false;
    }
    return acc;
  }, {});

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [isBoxChecked, setIsBoxChecked] = useState(checkedStatus);

  const handleCheckBox = (status) => {
    const selectedData = Object.keys(status).filter((key) => status[key]);
    cb(selectedData);
    setIsBoxChecked(status);
    setCheckCount(selectedData.length);
  };
  const handleBtn = () => setIsDropDownOpen(!isDropDownOpen);

  const handleCloseDropDown = () =>
    setTimeout(() => setIsDropDownOpen(false), 200);

  return (
    <div
      className="newCustomDropDown"
      onMouseLeave={handleCloseDropDown}
      {...props}
    >
      <button className="vertical-center" onClick={handleBtn}>
        {name}
        <div>
          {checkCount > 0 ? (
            <div className="countCheckedBox">+{checkCount}</div>
          ) : (
            <i
              className={`fa fa-chevron-right icon  ${
                isDropDownOpen && "open"
              }`}
            ></i>
          )}
        </div>
      </button>
      {isDropDownOpen && (
        <div className="newListContainer">
          {data?.map((value, idx) => {
            return (
              <div key={idx} className="newListCheckBoxBtn">
                <Checkbox
                  name={value}
                  checked={isBoxChecked[value]}
                  value={value}
                  onChange={(e) => {
                    handleCheckBox({
                      ...isBoxChecked,
                      [e.target.name]: !isBoxChecked[value],
                    });
                  }}
                  size="small"
                  className="newCheckBoxBtn"
                />
                <span>{value}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
