// import PropTypes from "prop-types";
import { EMPTY_ARRAY } from "../../../../assets/constants";
import "./specifications.css";

const Specifications = ({ data }) => {
  const specifications = data?.Specifications || EMPTY_ARRAY;
  if (specifications.length === 0) return null;
  //   if (!specifications) return null;
  return (
    <>
      {specifications?.map((specification, idx) => {
        const { Title = "", Descriptions = "" } = specification;

        return (
          <div className="row py-2 specificationBox my-1" key={idx}>
            <div className="col-md-4  specificationsTitle">{Title}</div>
            <div className="col-md-8 specificationsDescription">
              {Descriptions}
            </div>
          </div>
        );
      })}
    </>
  );
};

// Specifications.propTypes = {
//   data: PropTypes.shape({
//     Specifications: PropTypes.arrayOf(
//       PropTypes.shape({
//         Title: "PropTypes.string.isRequired,",
//         Descriptions: "PropTypes.string.isRequired,",
//       })
//     ).isRequired,
//   }).isRequired,
// };

// Specifications.defaultProps = {
//   data: { Specifications: [] },
// };

export default Specifications;
