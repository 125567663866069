import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const buttonContainerStyle = {
  position: "fixed",
  bottom: "1rem",
  right: ".5rem",
  zIndex: 1000,
};

const buttonStyle = {
  backgroundImage: "linear-gradient(135deg, #f34079 40%, #fc894d)",
  border: "none",
  padding: ".5rem",
  borderRadius: "50%",
  color: "#fff",
  cursor: "pointer",
  display: "flex",
};
const BackButton = () => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate(-1);
  };
  return (
    <div style={buttonContainerStyle}>
      <button style={buttonStyle} onClick={handleBackButton}>
        <ArrowBackIcon sx={{ fontSize: "1.25rem" }} />
      </button>
    </div>
  );
};

export default BackButton;
