import { useState } from "react";
import { EMPTY_ARRAY } from "../../../../assets/constants";
import PdfViewer from "../../../globalComponents/PdfViewer/PdfViewer";
import { BrochureDownloadForm } from "./BrochureDownloadForm";

const divStyle = {
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url('https://old.wealth-clinic.com/property_media/86841705386391.jpg')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  padding: "3rem 0",
};

const PrizeList = ({ data }) => {
  const [viewPdf, setViewPdf] = useState(null);

  const PRICES_PDF = data?.Add_Price_List?.Price_List?.data || EMPTY_ARRAY;
  if (PRICES_PDF.length <= 0) return null;

  let pdfUrl = PRICES_PDF[0]?.attributes?.url;

  return (
    <>
      <div className={` col-md-12  col-sm-12 col-xs-12`}>
        {viewPdf ? (
          <PdfViewer w="100%" h="500px" pdfUrl={pdfUrl} />
        ) : (
          <div className="" style={divStyle}>
            <div className="center-item mb-2 text-white">
              Fill The Form To Download Price List
            </div>
            <BrochureDownloadForm
              hideCloseBtn={false}
              styleForm="border"
              formBtnText="Request Download"
              formName="Brochure form from propertyDetails Page"
              pdfUrl={pdfUrl}
              setViewPdf={setViewPdf}
            />
          </div>
        )}
      </div>
      {/* <div className={` col-md-12  col-sm-12 col-xs-12`}>
        <div>
          <div>
            {PRICES_PDF?.map((item) => {
              const { url } = item?.attributes;
              return (
                <div className="">
                  <object
                    data={url}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  >
                    <p>
                      Your browser does not support PDFs.{" "}
                      <a href={url}>Download the PDF</a>.
                    </p>
                  </object>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default PrizeList;
