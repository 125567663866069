import React, { useState } from "react";
import ButtonDarkBlue from "../../../globalComponents/molecules/ButtonDarkBlue";
import InputCustom from "../../../globalComponents/molecules/InputCustom";
import SelectOption from "../../../globalComponents/molecules/SelectOption";

import { submissionOnZoho } from "../../../../assets/constants/zoho";
import { ALL_CITIES } from "../../../../assets/constants/cities";

const Gender = ["Male", "Female"];

const initialState = {
  formName: "Contact Us Form",
  name: "",
  email: "",
  phone: "",
  cityName: "",
  gender: "",
  message: "",
};

const ContactPageForm = () => {
  const [formValue, setFormValue] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formValue);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      await submissionOnZoho(formValue);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="border rounded-3 col-md-7 p-3 w-100"
    >
      <InputCustom
        placeholder="Name"
        className="px-3 rounded-2"
        name="name"
        value={formValue.name}
        onChange={handleChange}
      />
      {errors.name && <div className="text-danger">{errors.name}</div>}

      <InputCustom
        placeholder="Email"
        className="px-3 rounded-2"
        name="email"
        value={formValue.email}
        onChange={handleChange}
        required
      />
      {errors.email && <div className="text-danger">{errors.email}</div>}

      <InputCustom
        placeholder="Phone"
        className="px-3 rounded-2"
        name="phone"
        value={formValue.phone}
        onChange={handleChange}
        required
      />
      {errors.phone && <div className="text-danger">{errors.phone}</div>}

      <SelectOption
        data={ALL_CITIES}
        className="rounded-2"
        selectText="Select"
        name="cityName"
        value={formValue.cityName}
        onChange={handleSelectChange}
      />
      {errors.city && <div className="text-danger">{errors.city}</div>}

      <SelectOption
        data={Gender}
        selectText="I Am"
        className="rounded-2"
        name="gender"
        value={formValue.gender}
        onChange={handleSelectChange}
      />
      {errors.gender && <div className="text-danger">{errors.gender}</div>}

      <InputCustom
        placeholder="Message"
        className="rounded-3"
        name="message"
        value={formValue.message}
        onChange={handleChange}
      />

      <ButtonDarkBlue name="Contact Now" className="px-3 rounded-2" />
    </form>
  );
};

export default ContactPageForm;

const validateForm = (formValue) => {
  const newErrors = {};

  if (!formValue.name.trim()) newErrors.name = "Name is required.";
  if (!formValue.email.trim()) {
    newErrors.email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(formValue.email)) {
    newErrors.email = "Email is invalid.";
  }
  if (!formValue.phone.trim()) {
    newErrors.phone = "Phone is required.";
  } else if (!/^\d{10}$/.test(formValue.phone)) {
    newErrors.phone = "Phone must be 10 digits.";
  }
  if (!formValue.cityName) newErrors.city = "City is required.";
  if (!formValue.gender) newErrors.gender = "Gender is required.";

  return newErrors;
};
